import React from 'react';
import AllStore from './AllStore';
import '../Theme.css';
import './Store.css';

const FeaturedItems = () => {
  const Featured = AllStore.filter((featuredStore) =>
    featuredStore.type.includes('Featured')
  );

  return (
    <div>
      <h1>Featured Selection:</h1>
      <div className="behindText">
        <div className="storeWindow">
          {Featured.map((featuredStore) => (
            <div className="storeFrame behindText" key={featuredStore.id}>
              <a href={featuredStore.link}>
                <div className="basicText">{featuredStore.name}</div>
                <div>
                  <img src={featuredStore.img} alt={featuredStore.name} />
                </div>
                <div className="basicText">{featuredStore.info}</div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturedItems;

import React from 'react';
import { Link } from 'react-router-dom';

import AllNews from './AllNews';

import Helmet from 'react-helmet';

const Articles = () => {
  return (
    <div>
      <Helmet>
        <title>JMKit.com: News and Updates</title>
      </Helmet>
      <h1>News And Updates</h1>
      <div className="behindText">
        {AllNews.map((filteredNews) => (
          <Link to={{ pathname: `/articles/${filteredNews.id}` }}>
            <div className="articlePreview">
              <h2>
                {filteredNews.headline}: {filteredNews.date}
              </h2>
              <br />
              <div
                alt={filteredNews.headline}
                style={{
                  width: `100%`,
                  height: `120px`,
                  backgroundImage: `url(${filteredNews.img})`,
                  backgroundSize: `contain`,
                  backgroundRepeat: `no-repeat`,
                  backgroundPosition: `center`,
                }}
              ></div>
              <br />
              <div className="summery">{filteredNews.text}</div>
              <br />
              <div className="middleContent">
                <div
                  className="colorButton"
                  style={{
                    width: `140px`,
                  }}
                >
                  <h2>Read More</h2>
                </div>
              </div>
              <br />
              <hr />
              <br />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Articles;

import jinxShirt1 from '../../img/store/shirtJinx1.png'
import jinxHoodie1 from '../../img/store/hoodieJinx1.png'
import jinxPhone1 from '../../img/store/phoneJinx1.png'
import minxShirt1 from '../../img/store/shirtMinx1.png'
import minxHoodie1 from '../../img/store/hoodieMinx1.png'
import minxPhone1 from '../../img/store/phoneMinx1.png'
import childhood1 from '../../img/store/shirtChildhood.png'
import childhoodH1 from '../../img/store/hoodieChildhood.png'
import childhoodC1 from '../../img/store/pillowChildhood.png'
import childhoodM1 from '../../img/store/cupChildhood.png'
import childhood1ltd from '../../img/store/ltdShirtChildhood.png'
import childhoodH1ltd from '../../img/store/ltdHoodieChildhood.png'
import childhoodC1ltd from '../../img/store/ltdPillowChildhood.png'
import childhoodM1ltd from '../../img/store/ltdCupChildhood.png'

import PlaySetsCHoodie from '../../img/store/PlaySetsCHoodie.png'
import PlaySetsCShirt from '../../img/store/PlaySetsCShirt.png'
import PlaySetsCPillow from '../../img/store/PlaySetsCPillow.png'
import PlaySetsCCup from '../../img/store/PlaySetsCCup.png'

import book1 from '../../img/store/book1.png'

const AllStore = [
    {
        id: 0,
        type: ["T-Shirt", "Featured"],
        name: "JinxBunny Retro T-Shirt",
        img: jinxShirt1,
        colors: "https://www.jmkit.com/store/images/minxshirt1_colous.png",
        sCharacters: "JinxBunny",
        info: "More color options available",
        link: "https://teespring.com/en-GB/retro-jinx-bunny?tsmac=store&tsmic=jmkit-merch-2&pid=389&cid=100029"
    },
    {
        id: 1,
        type: ["T-Shirt", "Featured"],
        name: "MinxBunny Retro T-Shirt",
        img: minxShirt1,
        colors: "https://www.jmkit.com/store/images/minxshirt1_colous.png",
        sCharacters: "MinxBunny",
        info: "More color options available",
        link: "https://teespring.com/en-GB/retro-minx-bunny?tsmac=store&tsmic=jmkit-merch-2&pid=389&cid=100029"
    },
    {
        id: 2,
        type: "Hoodie",
        name: "JinxBunny Retro Hoodie",
        img: jinxHoodie1,
        colors: "https://www.jmkit.com/store/images/minxshirt1_colous.png",
        sCharacters: "JinxBunny",
        info: "More color options available",
        link: "https://teespring.com/en-GB/retro-jinx-bunny?tsmac=store&tsmic=jmkit-merch-2&pid=377&cid=100069"
    },
    {
        id: 3,
        type: "Hoodie",
        name: "MinxBunny Retro Hoodie",
        img: minxHoodie1,
        colors: "https://www.jmkit.com/store/images/minxshirt1_colous.png",
        sCharacters: "MinxBunny",
        info: "More color options available",
        link: "https://teespring.com/en-GB/retro-minx-bunny?tsmac=store&tsmic=jmkit-merch-2&pid=377&cid=100069"
    },
    {
        id: 4,
        type: "Phone Case",
        name: "JinxBunny Sunset Grid",
        img: jinxPhone1,
        colors: "",
        sCharacters: "JinxBunny",
        info: "iPhone and Samsung cases available",
        link: "https://teespring.com/en-GB/retro-sun-set-grid-jinx-bunny?tsmac=store&tsmic=jmkit-merch-2&pid=649&cid=103041"
    },
    {
        id: 5,
        type: "Phone Case",
        name: "MinxBunny Sunset Grid",
        img: minxPhone1,
        colors: "",
        sCharacters: "MinxBunny",
        info: "iPhone and Samsung cases available",
        link: "https://teespring.com/en-GB/retro-minx-bunny-grid?tsmac=store&tsmic=jmkit-merch-2&pid=707&cid=103122"
    },
    {
        id: 6,
        type: "Book",
        name: "Cute and Cool Character Colouring",
        img: "https://www.jmkit.com/images/store/books/coloringbook.jpg",
        colors: "",
        sCharacters: ["JinxBunny", "MinxBunny", "Tumble", "Boo", "Pepper", "Pixie", "Missel", "Dex", "Lil", "Bagle", "Chips", "Chloe", "Trott", "Flake", "Molly", "Jasper", "Lola", "Flake", "Bleu"],
        info: "Buy On Amazon",
        link: "https://www.amazon.com/dp/1523472081/ref=cm_sw_em_r_mt_dp_U_EHIvDb45HF55G"
    },
    {
        id: 7,
        type: "Book",
        name: "The Story of Jinx & Minx",
        img: book1,
        colors: "",
        sCharacters: ["JinxBunny", "MinxBunny"],
        info: "Download on Kindle",
        link: "https://www.amazon.com/dp/B0078FXAG6/ref=cm_sw_em_r_mt_dp_U_wyIvDb52JMXGM"
    },
    {
        id: 8,
        type: ["T-Shirt", "Childhood"],
        name: "JMKit Was My Childhood T-Shirt",
        img: childhood1,
        colors: "",
        sCharacters: ["JinxBunny", "MinxBunny"],
        info: "More color options available",
        link: "https://jmkit-merch-2.creator-spring.com/listing/jmkit-was-my-childhood?product=389"
    },
    {
        id: 9,
        type: ["Hoodie", "Featured"],
        name: "JMKit Was My Childhood Hoodie",
        img: childhoodH1,
        colors: "",
        sCharacters: ["JinxBunny", "MinxBunny"],
        info: "More color options available",
        link: "https://jmkit-merch-2.creator-spring.com/listing/jmkit-was-my-childhood?product=793"
    },
    {
        id: 10,
        type: ["Homewear", "My Childhood"],
        name: "JMKit Was My Childhood Pillow",
        img: childhoodC1,
        colors: "",
        sCharacters: ["JinxBunny", "MinxBunny"],
        info: "More color options available",
        link: "https://jmkit-merch-2.creator-spring.com/listing/jmkit-was-my-childhood?product=650"
    },
    {
        id: 11,
        type: ["Homewear", "MyChildhood"],
        name: "JMKit Was My Childhood Mug",
        img: childhoodM1,
        colors: "",
        sCharacters: ["JinxBunny", "MinxBunny"],
        info: "More color options available",
        link: "https://jmkit-merch-2.creator-spring.com/listing/jmkit-was-my-childhood?product=1566"
    },
    {
        id: 12,
        type: ["T-Shirt", "Halloween"],
        name: "LTD: JMKit Was My Childhood T-Shirt",
        img: childhood1ltd,
        colors: "",
        sCharacters: ["JinxBunny", "MinxBunny"],
        info: "Limited Edition for Halloween!",
        link: "https://jmkit-merch-2.creator-spring.com/listing/jmkit-was-my-childhood-hallow?product=389"

    },
    {
        id: 13,
        type: ["Hoodie", "Halloween"],
        name: "LTD: JMKit Was My Childhood Hoodie",
        img: childhoodH1ltd,
        colors: "",
        sCharacters: ["JinxBunny", "MinxBunny"],
        info: "Limited Edition for Halloween!",
        link: "https://jmkit-merch-2.creator-spring.com/listing/jmkit-was-my-childhood-hallow?product=793"
    },
    {
        id: 14,
        type: ["Homeware", "Halloween"],
        name: "JMKit Was My Childhood Pillow",
        img: childhoodC1ltd,
        colors: "",
        sCharacters: ["JinxBunny", "MinxBunny"],
        info: "More color options available",
        link: "https://jmkit-merch-2.creator-spring.com/listing/jmkit-was-my-childhood-hallow?product=650"
    },
    {
        id: 15,
        type: ["Homeware", "Halloween"],
        name: "JMKit Was My Childhood Mug",
        img: childhoodM1ltd,
        colors: "",
        sCharacters: ["JinxBunny", "MinxBunny"],
        info: "More color options available",
        link: "https://jmkit-merch-2.creator-spring.com/listing/jmkit-was-my-childhood-hallow?product=1566"
    },
    {
        id: 16,
        type: ["Hoodie", "PlaySets", "Featured"],
        name: "JMKit Playsets 'Cracked' Hoodie",
        img: PlaySetsCHoodie,
        colors: "",
        sCharacters: ["JinxBunny", "MinxBunny"],
        info: "More color options available",
        link: "https://jmkit-merch-2.creator-spring.com/listing/jmkit-playsets-cracked?product=793"
    },
    {
        id: 17,
        type: ["T-Shirt", "PlaySets"],
        name: "JMKit Playsets 'Cracked' T-Shirt",
        img: PlaySetsCShirt,
        colors: "",
        sCharacters: ["JinxBunny", "MinxBunny"],
        info: "More color options available",
        link: "https://jmkit-merch-2.creator-spring.com/listing/jmkit-playsets-cracked?product=389"
    },
    {
        id: 18,
        type: ["Homeware", "PlaySets"],
        name: "JMKit Playsets 'Cracked' Pillow",
        img: PlaySetsCPillow,
        colors: "",
        sCharacters: ["JinxBunny", "MinxBunny"],
        info: "More color options available",
        link: "https://jmkit-merch-2.creator-spring.com/listing/jmkit-playsets-cracked?product=650"
    },
    {
        id: 19,
        type: ["Homeware", "PlaySets"],
        name: "JMKit Playsets 'Cracked' Pillow",
        img: PlaySetsCCup,
        colors: "",
        sCharacters: ["JinxBunny", "MinxBunny"],
        info: "More color options available",
        link: "https://jmkit-merch-2.creator-spring.com/listing/jmkit-playsets-cracked?product=1695"
    },
]

export default AllStore;
import React from 'react';
import {
  CustomView,
  MobileView,
  isBrowser,
  isTablet,
} from 'react-device-detect';

import AllStore from '../Store/AllStore';
import './Feature.css';

const Feature = () => {
  const Featured = AllStore.filter((featuredStore) =>
    featuredStore.type.includes('Featured')
  );
  const MobFeature = Featured.slice(0 - 3);

  return (
    <div>
      <div className="behindText">
        <CustomView condition={isBrowser && !isTablet}>
          <div className="promo">SALE</div>
          <div className="storeWindow">
            {Featured.map((featuredStore) => (
              <div className="storeFrame3" key={featuredStore.id}>
                <a href={featuredStore.link}>
                  <div>
                    <img src={featuredStore.img} alt={featuredStore.name} />
                  </div>
                </a>
              </div>
            ))}
          </div>
        </CustomView>{' '}
        <MobileView>
          <div className="promo">SALE</div>
          <div className="storeWindowM">
            {MobFeature.map((featuredStore) => (
              <div className="storeFrameM" key={featuredStore.id}>
                <a href={featuredStore.link}>
                  <div>
                    <img src={featuredStore.img} alt={featuredStore.name} />
                  </div>
                </a>
              </div>
            ))}
          </div>
        </MobileView>
      </div>
    </div>
  );
};

export default Feature;

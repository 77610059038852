import React from 'react';
import Feature from './Feature';
import MainNews from './News/NewsFullSection';

import './Home.css';

function Home() {
  return (
    <div className="basicPageLeft">
      <h1>Feature</h1>
      <h2>20% off JMKit Merch!</h2>
      <br />
      <p align="center">
        Use code <b className="promocode">JINX20</b> at checkout to get 20% off
        anyhting you order!
        <br />
        Check Out The Official JMKit Merch Below!'
      </p>
      <Feature />
      <MainNews />
    </div>
  );
}

export default Home;

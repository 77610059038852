import React, { useState } from 'react';
import AllStore from './AllStore';
import '../Theme.css';
import './Store.css';
import FeaturedItems from './StoreFilter';

const StoreSections = () => {
  const [Choice, setChoice] = useState('T-Shirts');
  const [StoreCat, setStore] = useState('T-Shirt');

  function updateFeedT() {
    setChoice('T-Shirts');
    setStore('T-Shirt');
  }
  function updateFeedH() {
    setChoice('Hoodies');
    setStore('Hoodie');
  }
  function updateFeedB() {
    setChoice('Books');
    setStore('Book');
  }
  function updateFeedP() {
    setChoice('Phone Cases');
    setStore('Phone Case');
  }
  function updateFeedHW() {
    setChoice('Homeware');
    setStore('Homeware');
  }

  const storeType = AllStore.filter((storeChoice) =>
    storeChoice.type.includes(StoreCat)
  );

  const latest = storeType;

  return (
    <div>
      <FeaturedItems />
      <br />
      <div className="G_Tags">
        <li className="Tags" onClick={updateFeedT}>
          T-Shirts
        </li>
        <li className="Tags" onClick={updateFeedH}>
          Hoodies
        </li>
        <li className="Tags" onClick={updateFeedB}>
          Books
        </li>
        <li className="Tags" onClick={updateFeedP}>
          Phone Case
        </li>
        <li className="Tags" onClick={updateFeedHW}>
          HomeWares
        </li>
      </div>
      <h1>{Choice}</h1>
      <div className="behindText">
        <div className="storeWindow">
          {latest.map((filteredStore) => (
            <div className="storeFrame behindText" key={filteredStore.id}>
              <a href={filteredStore.link}>
                <div className="basicText">{filteredStore.name}</div>
                <div>
                  <img src={filteredStore.img} alt={filteredStore.name} />
                </div>
                <div className="basicText">{filteredStore.info}</div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StoreSections;

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import ThemeProvider from './ThemeProvider';
import MainContent from './Pages/MainContent';

import Header from './Pages/Header';
import DropDown from './Pages/Nav/DropDown';
import Characters from './Characters/Characters';
import CharacterPage from './Characters/CharacterPage';
import Comics from './Comics/Comics';
import ChatDen from './Chat/ChatDen';
import Videos from './Videos/Videos';
import VideoPlay from './Videos/VideoPlay';
import Crafts from './Crafts/Crafts';
import CraftsPage from './Crafts/CraftPage';
import Store from './Store/Store';
import Downloads from './Downloads/Downloads';
import Games from './Games/Games';
import GamePage from './Games/GamePage';
import GameTagResults from './Games/Tags/GameTagResults';
import GameTagCResults from './Games/Tags/GameTagCResults';
import Footer from './Pages/Footer';
import Home from './Pages/Home';
import GameDev from './GameDev/GameDiary';
import EntryPage from './GameDev/Entrypage';
import Articles from './Pages/News/Articles';
import ArticlePage from './Pages/News/ArticlePage';
import Alpha from './Pages/Alpha';
import NotFound from './Pages/NotFound';
import ArrowToTop from './ArrowToTop';

import './Pages/Pages.css';

import Helmet from 'react-helmet';
import ComicStrip from './Comics/ComicStrip';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <ArrowToTop />
        <div>
          <Helmet>
            <title>JMKit.com: Home</title>
          </Helmet>
          <div className="Main">
            <div className="contentWrap">
              <div className="topOver">
                <DropDown />
                <Header />
              </div>
              <div className="allPages">
                <MainContent>
                  <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route path="/characters" element={<Characters />} />
                    <Route
                      exact
                      path="/characters/:name"
                      element={<CharacterPage />}
                    />
                    <Route path="/comics" element={<Comics />} />
                    <Route
                      exact
                      path="/comics/:name"
                      element={<ComicStrip />}
                    />
                    <Route path="/videos" element={<Videos />} />
                    <Route path="/videos/:name" element={<VideoPlay />} />
                    <Route path="/crafts" element={<Crafts />} />
                    <Route path="/crafts/:name" element={<CraftsPage />} />
                    <Route path="/store" element={<Store />} />
                    <Route path="/downloads" element={<Downloads />} />
                    <Route path="/games" element={<Games />} />
                    <Route path="/games/:name" element={<GamePage />} />
                    <Route
                      path="/games/tags/:name"
                      element={<GameTagResults />}
                    />
                    <Route
                      path="/games/characters/:name"
                      element={<GameTagCResults />}
                    />
                    <Route path="/devdiary" element={<GameDev />} />
                    <Route path="/devdiary/:name" element={<EntryPage />} />
                    <Route path="/articles/" element={<Articles />} />
                    <Route path="/articles/:name" element={<ArticlePage />} />
                    <Route path="/chat/:name" element={<ChatDen />} />
                    <Route path="/alpha" exact element={<Alpha />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </MainContent>
              </div>
              <div className="Footer">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;

import React from "react";

function PlantPot(props) {
  return (
    <svg
      width="311"
      height="415"
      viewBox="0 0 311 415"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="path-1-outside-1"
        maskUnits="userSpaceOnUse"
        x="115.542"
        y="30.8259"
        width="104.078"
        height="199.972"
        fill="black"
      >
        <rect
          fill="white"
          x="115.542"
          y="30.8259"
          width="104.078"
          height="199.972"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M172.276 224.105C197.867 200.684 213.173 166.243 211.214 128.549C209.255 90.8558 190.449 57.9532 162.548 36.9454C136.957 60.3661 121.651 94.8076 123.611 132.501C125.57 170.195 144.376 203.097 172.276 224.105Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M172.276 224.105C197.867 200.684 213.173 166.243 211.214 128.549C209.255 90.8558 190.449 57.9532 162.548 36.9454C136.957 60.3661 121.651 94.8076 123.611 132.501C125.57 170.195 144.376 203.097 172.276 224.105Z"
        fill="#CCEA8C"
      />
      <path
        d="M172.276 224.105L170.481 226.496L172.479 228L174.311 226.323L172.276 224.105ZM162.548 36.9454L164.344 34.5546L162.346 33.0503L160.513 34.7274L162.548 36.9454ZM123.611 132.501L120.614 132.636L123.611 132.501ZM208.217 128.685C210.128 165.456 195.2 199.045 170.241 221.887L174.311 226.323C200.535 202.324 216.218 167.03 214.211 128.414L208.217 128.685ZM160.753 39.3362C187.964 59.825 206.306 91.9132 208.217 128.685L214.211 128.414C212.204 89.7984 192.933 56.0813 164.344 34.5546L160.753 39.3362ZM126.607 132.366C124.696 95.5946 139.624 62.0056 164.583 39.1634L160.513 34.7274C134.29 58.7267 118.606 94.0206 120.614 132.636L126.607 132.366ZM174.072 221.714C146.86 201.226 128.519 169.137 126.607 132.366L120.614 132.636C122.621 171.252 141.891 204.969 170.481 226.496L174.072 221.714Z"
        fill="black"
        mask="url(#path-1-outside-1)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M171.614 223.603L161.941 37.505C162.143 37.3177 162.345 37.1312 162.548 36.9454C190.449 57.9531 209.255 90.8558 211.214 128.549C213.173 166.243 197.867 200.684 172.276 224.105C172.055 223.938 171.834 223.771 171.614 223.603Z"
        fill="black"
        fillOpacity="0.1"
      />
      <mask
        id="path-4-outside-2"
        maskUnits="userSpaceOnUse"
        x="128.884"
        y="105.795"
        width="182.243"
        height="212.719"
        fill="black"
      >
        <rect
          fill="white"
          x="128.884"
          y="105.795"
          width="182.243"
          height="212.719"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M170.217 290.14C204.422 285.923 236.612 266.981 256.664 235.572C276.716 204.164 280.352 166.992 269.781 134.189C235.576 138.405 203.386 157.348 183.334 188.756C163.282 220.164 159.646 257.337 170.217 290.14Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M170.217 290.14C204.422 285.923 236.612 266.981 256.664 235.572C276.716 204.164 280.352 166.992 269.781 134.189C235.576 138.405 203.386 157.348 183.334 188.756C163.282 220.164 159.646 257.337 170.217 290.14Z"
        fill="#CCEA8C"
      />
      <path
        d="M170.217 290.14L167.361 291.06L168.122 293.421L170.584 293.117L170.217 290.14ZM269.781 134.189L272.637 133.268L271.876 130.908L269.414 131.211L269.781 134.189ZM254.135 233.958C234.583 264.583 203.202 283.051 169.85 287.162L170.584 293.117C205.642 288.795 238.64 269.378 259.192 237.187L254.135 233.958ZM266.926 135.109C277.233 167.094 273.687 203.333 254.135 233.958L259.192 237.187C279.744 204.995 283.471 166.889 272.637 133.268L266.926 135.109ZM185.863 190.37C205.415 159.745 236.796 141.278 270.148 137.166L269.414 131.211C234.356 135.533 201.357 154.95 180.805 187.142L185.863 190.37ZM173.072 289.22C162.765 257.234 166.31 220.996 185.863 190.37L180.805 187.142C160.253 219.333 156.527 257.439 167.361 291.06L173.072 289.22Z"
        fill="black"
        mask="url(#path-4-outside-2)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M169.968 289.358L268.967 134.292C269.239 134.256 269.51 134.222 269.781 134.189C280.352 166.992 276.716 204.164 256.664 235.572C236.612 266.981 204.422 285.923 170.217 290.14C170.133 289.88 170.05 289.619 169.968 289.358Z"
        fill="black"
        fill-opacity="0.1"
      />
      <mask
        id="path-7-outside-3"
        maskUnits="userSpaceOnUse"
        x="56.2493"
        y="96.0627"
        width="147.492"
        height="212.18"
        fill="black"
      >
        <rect
          fill="white"
          x="56.2493"
          y="96.0627"
          width="147.492"
          height="212.18"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M158.248 290.258C176.835 261.235 182.8 224.365 171.421 188.881C160.043 153.398 133.749 126.872 101.75 114.071C83.1627 143.093 77.1979 179.964 88.5765 215.447C99.9552 250.931 126.249 277.457 158.248 290.258Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.248 290.258C176.835 261.235 182.8 224.365 171.421 188.881C160.043 153.398 133.749 126.872 101.75 114.071C83.1627 143.093 77.1979 179.964 88.5765 215.447C99.9552 250.931 126.249 277.457 158.248 290.258Z"
        fill="#CCEA8C"
      />
      <path
        d="M158.248 290.258L157.134 293.043L159.437 293.964L160.774 291.876L158.248 290.258ZM101.75 114.071L102.864 111.285L100.561 110.364L99.2234 112.453L101.75 114.071ZM168.565 189.797C179.659 224.396 173.845 260.341 155.722 288.64L160.774 291.876C179.825 262.13 185.94 224.334 174.278 187.965L168.565 189.797ZM100.635 116.856C131.837 129.338 157.47 155.198 168.565 189.797L174.278 187.965C162.616 151.597 135.661 124.406 102.864 111.285L100.635 116.856ZM91.4333 214.531C80.3383 179.932 86.1524 143.988 104.276 115.689L99.2234 112.453C80.173 142.199 74.0575 179.995 85.7198 216.363L91.4333 214.531ZM159.362 287.472C128.161 274.99 102.528 249.13 91.4333 214.531L85.7198 216.363C97.3822 252.732 124.337 279.923 157.134 293.043L159.362 287.472Z"
        fill="black"
        mask="url(#path-7-outside-3)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.487 289.95L101.31 114.763C101.456 114.532 101.602 114.301 101.75 114.071C133.749 126.872 160.043 153.398 171.421 188.881C182.8 224.365 176.835 261.235 158.248 290.258C157.994 290.156 157.741 290.054 157.487 289.95Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        d="M91 283.5H89.3516L89.5067 285.141L101.507 412.141L101.635 413.5H103H239H240.376L240.494 412.129L251.494 285.129L251.636 283.5H250H91Z"
        fill="#D7A17A"
        stroke="black"
        strokeWidth="3"
      />
      <rect
        x="77.5"
        y="259.5"
        width="186"
        height="32"
        rx="11.5"
        fill="#D7A17A"
        stroke="black"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M171 412V261H252C257.523 261 262 265.477 262 271V280C262 285.523 257.523 290 252 290H249.567L239 412H171Z"
        fill="black"
        fillOpacity="0.1"
      />
    </svg>
  );
}

export default PlantPot;

import React from 'react';

import AllNews from './AllNews';
import ArticleMain from './ArticleMain';

import { useLocation } from 'react-router-dom';

const ArticlePage = () => {
  const location = useLocation();
  const thisArticle = location.pathname.split('/').pop();
  const actualNum = parseFloat(thisArticle);

  return (
    <div>
      {AllNews.filter((article) => article.id === actualNum).map(
        (filteredArticle) => (
          <ArticleMain key={filteredArticle.id} article={filteredArticle} />
        )
      )}
    </div>
  );
};

export default ArticlePage;

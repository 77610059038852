import React from 'react';
import { Link } from 'react-router-dom';
import './Diary.css';

import AllDiary from './AllDiary';

const Entries = () => {
  return (
    <div>
      <div className="diaryPage">
        {AllDiary.reverse().map((filteredEnteries) => (
          <Link to={{ pathname: `/devdiary/${filteredEnteries.id}` }}>
            <div className="diaryEntry">
              <div className="diaryEntryTop" />
              <div className="typedHeadline">{filteredEnteries.headline}</div>
              <br />
              <div className="summery">{filteredEnteries.previewText}</div>
              <br />
              <img src={filteredEnteries.icon} alt="icon"></img>
              <div className="middleContent">
                <div className="diaryButton">Read More..</div>
              </div>
              <div
                className="diaryEntryBottom"
                style={{ backgroundImage: `url(${filteredEnteries.base})` }}
              />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Entries;

import React from 'react';
import StoreSections from './StoreSections';

import '../Theme.css';
import './Store.css';

import Helmet from 'react-helmet';

const Store = () => {
  return (
    <div>
      <Helmet>
        <title>JMKit.com: Store</title>
      </Helmet>
      <h1>Store Page</h1>
      <div className="basicPage">
        <h3>The latest official JMKit Merch!</h3>
        <br />
        <StoreSections />
      </div>
    </div>
  );
};

export default Store;

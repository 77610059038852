import React from 'react';

import Moon from '../../img/hills/moon.png';
import Sun from '../../img/hills/sun.png';
import Rainbow from '../../img/hills/rainbow.png';
import TopBanner from './TopBanner';
//below for sunny V
//import HillsTopper from '../../img/SVG/greenHills.svg';
//below for halloween V
// import HillsTopperH from '../../img/SVG/HillTopperH.svg';
// import Ghost from '../../img/SVG/Ghost.svg';
// import Tower from '../../img/SVG/Tower.svg';
//below for winter V
import HillsTopperW from '../../img/SVG/HillTopperW.svg';

import '../../animations.css';

const HillTopper = () => {
  return (
    <div>
      <div className="hillTopper">
        <div id="moon">
          <img src={Moon} alt="The Moon" />
        </div>
        <div id="sun">
          <img src={Sun} alt="The Sun" />
        </div>
        <div id="rainbow">
          <img src={Rainbow} alt="Pride" />
        </div>
        {/* <div id="tower">
          <img src={Tower} alt="Tower" />
        </div> */}
        <div
          className="hills"
          style={{
            backgroundImage: `url(${HillsTopperW})`,
          }}
        >
          <TopBanner />
        </div>
      </div>
      <div className="underHill">&nbsp;</div>
      {/* <div id="ghost">
        <img src={Ghost} alt="Spooky" />
      </div>{' '} */}
    </div>
  );
};

export default HillTopper;

import React from 'react';

import AllGames from './AllGames';
import GamePageMain from './GamePageMain';

import { useLocation } from 'react-router-dom';

import './Games.css';

const GamePage = () => {
  const location = useLocation();
  const thisGame = location.pathname.split('/').pop();

  return (
    <div align="center" className="basicPage">
      {AllGames.filter((game) => game.urlName === `${thisGame}`).map(
        (filteredGames) => (
          <GamePageMain key={filteredGames.id} game={filteredGames} />
        )
      )}
    </div>
  );
};

export default GamePage;

/*updated*/

import React from 'react';

import Entries from './Entries';

const GameDiary = () => {
  return (
    <div>
      <h2>Game Dev Diary</h2>
      <br />
      <div className="basicPage">
        <div className="behindText">
          <h1>Ravensmoor Manor</h1>
          Ravensmoor Manor is a brand new JMKit adventure game featuring Jinx
          and Minx.
          <br />
          Check out updates as I go along working on this my most ambitious and
          exciting project!
          <br />
          <br />
          <br />
          <Entries />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default GameDiary;

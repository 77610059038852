let Video = `https://www.jmkit.com/images/icons/icon_Video.png`
let Image = `https://www.jmkit.com/images/icons/icon_Image.png`
let Note = `https://www.jmkit.com/images/icons/icon_note.png`
let B1 = `https://www.jmkit.com/images/NoteBase1.png`
let B2 = `https://www.jmkit.com/images/NoteBase2.png`
let B3 = `https://www.jmkit.com/images/NoteBase3.png`

const AllDiary = [
    {
        id: 0,
        date: "Nov 12 2021",
        icon: Video,
        base: B1,
        type: "Video",
        ytEmbedLink: "https://www.youtube.com/embed/iU20BtoMpi4",
        headline: "Feature: Characters",
        previewText: "Play as both Jinx and Minx...",
        vidLink: "https://youtu.be/iU20BtoMpi4",
        imgLink: "Null",
        mainText1: `You can now 'Hot-Swap' between characters for the first time in a JMKit game.In the Tower escape game you would play as both Jinx and Minx but you would only switch after a floor was cleared, now you'll have the oppertunity to swap between characters whenever you'd like: `,
        gTags: [
            "Players",
        ],
        gCharacters: [
            "JinxBunny", "MinxBunny"
        ],
    },
    {
        id: 1,
        date: "Nov 29 2021",
        icon: Image,
        base: B2,
        type: "Image",
        imageLink: "https://jmkit.com/images/devdiary/inventory1.png",
        img: "null",
        headline: "Feature: Inventory",
        previewText: "Brand new Backpack Inventory System...",
        imgLink: "https://jmkit.com/images/devdiary/inventory1.png",
        mainText1: "Jinx and Minx each have their own Backpack allowing them both to carry, inspect and use different items, here's an example of Jinx looking through his backpack at the items he has available.*",
        mainText2: "*Note: Not all images / descriptions are final",
        gTags: [
            "Inventory",
        ],
        gCharacters: [
            "JinxBunny"
        ],
    },
    {
        id: 2,
        date: "Nov 29 2021",
        icon: Image,
        base: B3,
        type: "Image",
        imageLink: "https://jmkit.com/images/devdiary/talk1.png",
        img: "null",
        headline: "Feature: Talking",
        previewText: "Choose what to reply..",
        imgLink: "https://jmkit.com/images/devdiary/inventory1.png",
        mainText1: "When talking to another character you can choose between replies right there in the speach bubble - highlighted text and arrows either side of the bubble will indicate there are more options, and they might be worth checking out as a different line of conversation can get you additional information!",
        mainText2: "*Note: Not all images / descriptions are final",
        gTags: [
            "Talking",
        ],
        gCharacters: [
            "JinxBunny", "MinxBunny"
        ],
    },
    {
        id: 3,
        date: "Jan 02 2023",
        icon: Note,
        base: B1,
        type: "Note",
        imageLink: "null",
        img: "null",
        headline: "One Year On..",
        previewText: "What's been going on??",
        imgLink: "null",
        mainText1: "Well more than a year has passed and the game has not released, but fear not I have been working on and off for all that time. I have approached this project differently to many other JMKit games. I've been experimenting with different mechanics and ideas to make it the game I'd like to play - to make my ideas that come in to my mind, come to life and be fun to play",
        mainText2: "Life can also get in the way of development and can cause expected (and unexpected) delays, but I'm still keen to make this project a reality and will aim to showcase more of what I've been working on in the coming weeks and months!",
        gTags: [
            "Update",
        ],
        gCharacters: [
            "JinxBunny", "MinxBunny"
        ],
    },
]

export default AllDiary;
import React from 'react';

import AllDiary from './AllDiary';
import EntryMain from './EntryMain';

import { useLocation } from 'react-router';

const EntryPage = () => {
  const location = useLocation();
  const thisDiary = location.pathname.split('/').pop();
  const actualNum = parseFloat(thisDiary);

  return (
    <div>
      {AllDiary.filter((diary) => diary.id === actualNum).map((diaryEntry) => (
        <EntryMain key={diaryEntry.id} diaryPage={diaryEntry} />
      ))}
    </div>
  );
};

export default EntryPage;

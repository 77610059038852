import React from 'react';

import AllCharacters from './AllCharacters';
import CharacterPageMain from './CharacterPageMain';

import { useLocation } from 'react-router-dom';

import './Characters.css';

const CharacterPage = () => {
  const location = useLocation();
  const thisCharacter = location.pathname.split('/').pop();

  return (
    <div>
      {AllCharacters.filter(
        (character) => character.urlName === `${thisCharacter}`
      ).map((filteredCharacters) => (
        <CharacterPageMain
          key={filteredCharacters.id}
          thisCharacter={filteredCharacters}
        />
      ))}
    </div>
  );
};

export default CharacterPage;
